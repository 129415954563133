'use strict';

angular.module('metabuyer').constant('pathConstants', (function () {
  var ip = 'https://uat.metabuyercloud.com';

  /**
   * Rollbar variables
   * possible environment value: production, staging, stg, development, local, sit
   * DEVELOPERS MUST USE local on their local machine.
   * Optional: for developers you can change the value of 'verbose' to true to check the Rollbar errors in the console log
   * the Initial values are for development
   */
  var token = '""';
  var captureUncaught = true;
  var environment = 'development';
  var buildNumber = '50';
  var verbose = false;
  var appLocation = 'online';
  var isSourcingModeEnabled  = true;

  return {
    environmentUrl: ip,
    RollbarConfiguration: {
      token: token,
      captureUncaught: captureUncaught,
      environment: environment,
      verbose: verbose,
      buildNumber: buildNumber
    },
    appLocation: appLocation,
    isSourcingModeEnabled: isSourcingModeEnabled,
    baseUrl: '/',
    apiUrls: {
      login: ip + '/api/users/login',
      systemVersionNumber: ip + '/api/system/get-system-version-number',
      profile: ip + '/api/users/me',
      dashboard: ip + '/api/users/dashboard',
      po: {
        list: ip + '/api/po',
        single: ip + '/api/po/:id',
        delete: ip + '/api/po/deleted/:id',
        status: ip + '/api/po/:id/:status',
        search: ip + '/api/po/search?example[creator]=:title',
        resend: ip + '/api/po/:id/resend',
        comment: ip + '/api/po/:id/comment/:comment_id',
        listAttachment: ip + '/api/po/:po_id/attach',
        singleAttachment: ip + '/api/po/:po_id/attach/:attachment_id',
        downloadAttachment: ip + '/api:hash',
        hash: ip + '/api/po/:id/pdf',
        pdfHash: ip + '/api:pdf_hash',
        movePdfToInvalid: ip + '/api/po/:id/move-pdf-to-invalid',
        deliverGoods: ip + '/api/delivery-note',
        deliverGoodsDetails: ip + '/api/delivery-note/:id',
        deliveryNoteHash: ip + '/api/delivery-note/:id/pdf',
        receive: ip + '/api/po/:po_id/receive',
        reverse: ip + '/api/po/:po_id/reverse',
        closeLineItem: ip + '/api/po/:po_id/items/:item_id',
        grn: ip + '/api/grn/:id',
        getGRNPDFHash: ip + '/api/grn/:id/pdf',
        getPOGRNs: ip + '/api/po/:id/goods-received',
        massUpdatePOStatus: ip + '/api/po/mass-update-status',
        pendingActionSupplier: ip + '/api/po/pending-action-supplier-user',
        getErpStatus: ip + '/api/po/erp-status',
        retryOutboundPo: ip + '/api/po/retry-outbound-po',
        pushPoToBifrost: ip + '/api/po/push-po-to-bifrost',
        requestResyncDelivery: ip + '/api/po/request-resync-delivery',
        starring: ip + '/api/po/:id/star/user/:user',
        updateItemDetails: ip + '/api/purchase-order/:id',
        checkToleranceLimit: ip + '/api/purchase-order/check-tolerance-limit',
        resendPendingPoEmail: ip + '/api/purchase-order/:id/resend-pending-email',
      },
      users: {
        list: ip + '/api/users/',
        search: ip + '/api/users/search?:text',
        single: ip + '/api/users/:id',
        roleAssignments: ip + '/api/users/:id/role-assignments',
        status: ip + '/api/users/:id/:status',
        listPOStar: ip + '/api/users/:id/stared_pos',
        suppliersSearch: ip + '/api/suppliers/search',
        updatingPassword: ip + '/api/users/pass_reset',
        adminUnlockUser: ip + '/api/users/unlock/:id',
        activeUsers: ip + '/api/users/sessions/:session_id',
        image: ip + '/api/users/:id/image',
        notifications: ip + '/api/users/notifications/:id',
        unreadNotifications: ip + '/api/users/notifications/unread',
        readNotifications: ip + '/api/users/notifications/read',
        countUnreadNotifications: ip + '/api/users/notifications/unread/count',
        forgottenPassword: ip + '/api/users/forgot_password',
        importManager: ip + '/api/users/import_manager',
        importAssignments: ip + '/api/users/import_user_roles',
        exportAssignments: ip + '/api/users/export-user-assignments',
        budgets: ip + '/api/users/budgets',
        pendingApproval: ip + '/api/users/:userId/pending-approval/:companyCode',
        supplierWeb: ip + '/api/supplier-web/users/:id',
        supplierWebTnc: ip + '/api/supplier-web/users/:id/tnc',
        supplierWebUserHistory: ip + '/api/supplier-web/users-history',
        supplierWebUserStatus: ip + '/api/supplier-web/users/:id/status',
        supplierWebUserResetPassword: ip + '/api/supplier-web/users/:id/reset-password',
        setAsMetabuyerUser: ip + '/api/supplier-web/users/:id/set-as-metabuyer-user',
        approvalTrack: ip + '/api/users/:uuid/approval-track/:approval_limit_uuid',
        starItems: ip + '/api/users/me/star-item',
        viewItems: ip + '/api/users/me/view-item',
        loggedInUserApprovalTrack: ip + '/api/users/:approval_limit_uuid/user-approval-tracks',
        addApprovalLimit: ip + '/api/users/:uuid/add-approval-limit',
        updateApprovalLimitStatus: ip + '/api/users/:user_uuid/approval-limit/:approval_limit_uuid/update-status',
        getUserApprovalLimitsBalance: ip + '/api/users/:user_uuid/approval-limits-balance',
        categories: ip + '/api/users/categories',
        timezones: ip + '/api/users/timezones',
        securityQuestion: ip + '/api/users/security-question',
        getSecurityQuestionList: ip + '/api/users/security-question-list',
        getSecurityQuestionByEmail: ip + '/api/users/security-question/email',
        submitSecurityQuestionByEmail: ip + '/api/users/security-question/email/submit',
        downloadCSV: ip + '/api/users/download-csv'
      },
      companies: {
        list: ip + '/api/companies',
        allCompanies: ip + '/api/companies/project-by-fields',
        single: ip + '/api/companies/:id',
        costCenters: ip + '/api/companies/:id/costcenters',
        erpCostCenterList: ip + '/api/companies/:id/erpcostcenters',
        erpOrderTypeList: ip + '/api/companies/:id/erpordertypes',
        erpOrderType: ip + '/api/companies/erpordertype/:id',
        erpCostCenter: ip + '/api/companies/erpcostcenter/:id',
        accountCode: ip + '/api/companies/:id/account_code',
        currencies: ip + '/api/companies/:id/currencies',
        activeUsers: ip + '/api/companies/:id/active-users',
        usersInCompanies: ip + '/api/companies/users',
        image: ip + '/api/companies/:id/image/:type',
        search: ip + '/api/companies/search?query=:query',
        referenceCounter: ip + '/api/companies/:companyId/reference_counter/:referenceId/:action',
        referenceCounterSearch: ip + '/api/companies/:companyId/reference_counter/search',
        import: ip + '/api/companies/import',
        expenseTypes: ip + '/api/companies/:id/expense_types',
        costCentersAddress: ip + '/api/companies/:companyId/export-cost-centers',
        approvalSettings: ip + '/api/companies/:id/approval-settings',
        exportItemTax: ip + '/api/companies/:companyId/export-item-tax',
        itemTaxHistory: ip + '/api/companies/:id/item-tax-history',
        downloadItemTax: ip + '/api/companies/:companyId/download-item-tax/:attachmentId',
        purchaseRequisitionSettings: ip + '/api/companies/:id/purchase-requisition-settings',
        erpIntegrationSettings: ip + '/api/companies/:id/erp-integration-settings',
        otherInformation: ip + '/api/companies/:id/other-information',
        billingInformation: ip + '/api/companies/:id/billing-information',
        exportUserAssignments: ip + '/api/companies/:id/export-user-assignments',
        supplierWeb: ip + '/api/companies/:id/supplier-web',
        purchaseOrderSettings: ip + '/api/companies/:id/purchase-order-settings',
        toleranceLimit: ip + '/api/companies/:id/toleranceLimit',
        automatedPo: ip + '/api/companies/:id/automatedPo',
        setDefaultAddress: ip + '/api/companies/:id/update-default-address',
        consolidationExpired: ip + '/api/companies/:id/consolidationExpired',
        address: ip + '/api/companies/:id/address',
        exportCompanyAddresses: ip + '/api/companies/:companyId/address/export-addresses/:type',
        importCompanyAddresses: ip + '/api/companies/address/import-addresses',
        modeOfPurchase: ip + '/api/companies/:id/mode-of-purchase',
        billingFromCompanies: ip + '/api/companies/:code/get-billed-from-companies',
        priceComparison: ip + '/api/companies/:id/price-comparison-settings'
      },
      companyProject: {
        single: ip + '/api/project/:uuid'
      },
      research: {
        new: ip + '/api/research/store',
        single: ip + '/api/research/:id'
      },
      specialEvent: {
        new: ip + '/api/special-event/store',
        single: ip + '/api/special-event/:id'
      },
      studentLife: {
        new: ip + '/api/student-life-event/store',
        single: ip + '/api/student-life-event/:id'
      },
      costCenters: {
        new: ip + '/api/costcenters/',
        list: ip + '/api/costcenters',
        single: ip + '/api/costcenters/:id',
        deactivate: ip + '/api/costcenters/:id/deactivate',
        activate: ip + '/api/costcenters/:id/activate',
        import: ip + '/api/costcenters/:companyId/import',
        activeUsers: ip + '/api/costcenters/:id/active-users/'
      },
      expenseTypes: {
        masterList: ip + '/api/expense_types/:id',
        list: ip + '/api/expense_types/list/all',
        search: ip + '/api/expense_types/search/?query=:query',
        import: ip + '/api/expense_types/import'
      },
      modeOfPurchase: {
        single: ip + '/api/mode-of-purchase/:id'
      },
      categoryType: {
        single: ip + '/api/category-type/:id'
      },
      itemGroup: {
        single: ip + '/api/item-group/:uuid',
        updateStatus: ip + '/api/item-group/:uuid/status'
      },
      sourcingExpenseType: {
        single: ip + '/api/sourcing-expense-type/:uuid',
        updateStatus: ip + '/api/sourcing-expense-type/:uuid/status'
      },
      sourcingCategory: {
        single: ip + '/api/sourcing-category/:uuid',
        updateStatus: ip + '/api/sourcing-category/:uuid/status'
      },
      sourcingProject: {
        single: ip + '/api/sourcing-project/:uuid',
        updateStatus: ip + '/api/sourcing-project/:uuid/status'
      },
      companyOpexIo: {
        single: ip + '/api/companies/opex-io/:id'
      },
      companyOtherChargesGroup: {
        single: ip + '/api/companies/:id/other-charges-groups'
      },
      getOtherCharges: {
        all: ip + '/api/other-charges'
      },
      getOtherChargesGroup: {
        single: ip + '/api/companies/other-charges-groups/:uuid'
      },
      currencies: {
        list: ip + '/api/currencies/list/all',
        all: ip + '/api/currencies',
        masterList: ip + '/api/currencies/:id',
        search: ip + '/api/currencies/search/?query=:query',
        import: ip + '/api/currencies/import'
      },
      contactPersons: {
        contacts: ip + '/api/contact',
        single: ip + '/api/contact/:id'
      },
      companyAddress: {
        single: ip + '/api/address/:company_id'
      },
      comments: {
        listPRNewComment: ip + '/api/prsNew/:referenceId/comment',
        listPOComment: ip + '/api/po/:referenceId/comment',
        listSupplierTagHistory: ip + '/api/supplierTag/:companyCode/comment',
        listAlcComment: ip + '/api/alc/:referenceId/comment',
        saveAlcComment: ip + '/api/alc/:referenceId/comment',
        savePOComment: ip + '/api/po/:referenceId/comment/:comment_id',
        savePRNewComment: ip + '/api/prsNew/:referenceId/comment/:commentId',
        reference: ip + '/api/comments-history/:referenceId',
        listBudgetComment: ip + '/api/budgets/:referenceId/comments',
        saveBudgetComment: ip + '/api/budgets/:referenceId/comments/:commentId',
        single: ip + '/api/comments-history/:commentId'
      },
      attachments: {
        listPRNewAttachment: ip + '/api/prsNew/:pr_id/attachment',
        singlePRNewAttachment: ip + '/api/prsNew/:pr_id/attachment/:attachmentId',
        downloadAttachment: ip + '/api/attachment/download/:hash',
        downloadErrorLog: ip + '/api/attachment/download/log/:hash',
        file: ip + '/api/attachment',
        detail: ip + '/api/attachment/:id',
        download: ip + '/api/attachment/download/:hash',
        reference: ip + '/api/attachments/:referenceId',
        single: ip + '/api/attachment/:attachmentId'
      },
      starring: {
        listPO: ip + '/api/po/:id/:action'
      },
      catalog: {
        list: ip + '/api/users/:user_id/items/:code',
        search: ip + '/api/users/:user_id/items/:type',
        details: ip + '/api/users/:user_id/items/:item_id/details',
        searching: ip + '/api/users/:user_id/items/search/',
        starringItem: ip + '/api/users/:user_id/items/:item_id/starred',
        v2: {
          search: ip + '/api/v2/users/:user_id/catalog-items/:type',
          details: ip + '/api/v2/catalogs/:uuid/details',
          histories: ip + '/api/v2/catalogs/:uuid/histories',
          imagesImport: ip + '/api/v2/catalogs/:uuid/images',
          quantityControlInfo: ip + '/api/v2/catalogs/:uuid/quantity-control-info',
          comments: ip + '/api/v2/catalogs/:referenceId/comments'
        }
      },
      catalogs: {
        checkImport: ip + '/api/catalogs/validate-before-import',
        import: ip + '/api/catalogs/import',
        updateStatus: ip + '/api/catalogs/update-status/:id'
      },
      items: {
        list: ip + '/api/items/:status',
        delete: ip + '/api/items/:id',
        detail: ip + '/api/items/:id/details',
        import: ip + '/api/items/import',
        changeStatus: ip + '/api/items/:status/:id',
        imagesImport: ip + '/api/items/images/',
        nonCatalog: ip + '/api/users/:id/item',
        manageNonCatalog: ip + '/api/users/:user_id/item/:item_id/:action?itemName=:itemName&pr_cc=:pr_cc',
        itemDetailStarring: ip + '/api/users/:user_id/items/:item_id/:group',
        catalogVersion: ip + '/api/items/:summary_id/history',
        forceDeactivateCatalogItems: ip + '/api/items/:summary_id/forceDeactivateItems',
        download: ip + '/api/items/:summary_id/attachment/:attachment_id',
        singleItemDetail: ip + '/api/items/:status/:summary_id/details/:import_item_id',
        checkImport: ip + '/api/items/import/validate/:supplier_id/:BU/:catalog_code_id',
        imagesUpload: ip + '/api/users/:user_id/item/:item_id/details/:detail_id/image',
        historyDetails: ip + '/api/items/:summary_id/details/',
        itemDetailsListing: ip + '/api/items/:summary_id/details/:status',
        singleItemDetails: ip + '/api/items/:summary_id/details/:import_item_id/info',
        listCatalogCode: ip + '/api/items/catalog_code/:supplier_id/:BU',
        registerViewedItem: ip + '/api/users/:user_id/viewed-item/:item_id',
        companyItems: {
          single: ip + '/api/company-items/:uuid',
          multiple: ip + '/api/company-items/shopping-status'
        },
        catalogItemList: ip + '/api/items/catalog-item'
      },
      catalogsV2: {
        checkImport: ip + '/api/v2/catalogs/validate-before-import',
        list: ip + '/api/v2/catalogs/:status',
        import: ip + '/api/v2/catalogs/import',
        updateStatus: ip + '/api/v2/catalogs/:id/update-status',
        download: ip + '/api/v2/catalogs/:catalog_id/attachment/:attachment_id',
        downloadWithHash: ip + '/api/v2/catalogs/:hash/catalogAttachment',
        resendPendingCatalogEmail: ip + '/api/v2/catalogs/:id/resend-pending-email',
      },
      itemsCategories: {
        list: ip + '/api/commodity/list/:code'
      },
      notification: {
        count: ip + '/api/notifications/count',
        subscribe: ip + '/api/notifications/:method',
        subscriptionCheck: ip + '/api/notifications/is_subscribed'
      },
      waitingOnUser: {
        list: ip + '/api/waiting'
      },
      tax: {
        list: ip + '/api/tax/list/all',
        masterList: ip + '/api/tax/:id',
        search: ip + '/api/tax/search/?query=:query',
        import: ip + '/api/tax/import'
      },
      uom: {
        list: ip + '/api/uoms/list/all?query=:query',
        single: ip + '/api/uoms/:id',
        search: ip + '/api/uoms/search/',
        import: ip + '/api/uoms/import'
      },
      itemMaster: {
        single: ip + '/api/item-master/:id'
      },
      itemCategory: {
        single: ip + '/api/item-categories/:uuid'
      },
      stockInformationGroup: {
        single: ip + '/api/stock-information-groups/:uuid',
        update: ip + '/api/companies/stock-info-group/',
        list: ip + '/api/search/stock-information-group'
      },
      alcGroup: {
        single: ip + '/api/alc-groups/:uuid',
        update: ip + '/api/companies/alc-group/',
        list: ip + '/api/search/alc-group',
        activeUsers: ip + '/api/alc-groups/:code/active-users'
      },
      alc: {
        draft: ip + '/api/alc/drafts/:uuid',
        single: ip + '/api/alc/:uuid',
        list: ip + '/api/alc',
        update: ip + '/api/alc/:uuid/update-status',
        utilization: ip + '/api/alc/:uuid/utilization',
        updateAlcHeader: ip + '/api/alc/:uuid/update-header-info',
        resendPendingAlcEmail: ip + '/api/alc/:id/resend-pending-email',
      },
      supplier: {
        list: ip + '/api/suppliers/v2',
        single: ip + '/api/suppliers/v2/:id',
        new: ip + '/api/suppliers/v2',
        newDraft: ip + '/api/suppliers/v2/drafts',
        newDraftAttachment: {
          add: ip + '/api/suppliers/v2/drafts/:draft_id/attachment',
          delete: ip + '/api/suppliers/v2/drafts/:draft_id/attachment/:attachment_id'
        },
        supplierDraft: ip + '/api/suppliers/v2/drafts/:id',
        companyContract: ip + '/api/suppliers/v2/:id/company-contract',
        companyContractRevisions: ip + '/api/suppliers/v2/:id/company-contract/:company_code/revisions',
        listCompanyContracts: ip + '/api/suppliers/v2/:id/company-contracts',
        status: ip + '/api/suppliers/v2/:id/status',
        updatedSupplier: ip + '/api/suppliers/v2/:id/personal',
        updateStatus: ip + '/api/suppliers/v2/:id/update-status',
        resubmit: ip + '/api/suppliers/v2/:id/resubmit',
        deleteSupplier: ip + '/api/suppliers/v2/:id/delete',
        attachment: {
          add: ip + '/api/suppliers/v2/:supplier_id/attachment',
          delete: ip + '/api/suppliers/v2/:supplier_id/attachment/:attachment_id'
        },
        branches: ip + '/api/suppliers/v2/:id/branches',
        singleCompanyContract: ip + '/api/suppliers/v2/:id/company-contract/:code',
        tagViaCompany: ip + '/api/supplier_tag/companyCode',
        tagBySupplierCode: ip + '/api/supplier_tag/:supplierCode/suppliercode',
        tagByCompanyCode: ip + '/api/supplier_tag/:companyCode/companycode',
        pushSupplierToBifrost: ip + '/api/suppliers/v2/:id/push-supplier-to-bifrost',
        updateSageCompanyCode: ip + '/api/suppliers/v2/:id/update-sage-company-code',
        updateSupplierCompanyContract: ip + '/api/suppliers/v2/:id/update-supplier-company-contract',
        previewApproval: ip + '/api/suppliers/v2/preview-approval'
      },
      supplierUser:  {
        supplierUser: ip + '/api/supplier-user/:id',
        search: ip + '/api/supplier-user/search',
        performAction: ip + '/api/supplier-user/perform-action/:action',
        comments: ip + '/api/supplier-user/:referenceId/comments'
      },
      paymentMethods: {
        list: ip + '/api/payment_method/list/all',
        masterList: ip + '/api/payment_method/',
        single: ip + '/api/payment_method/:id',
        search: ip + '/api/payment_method/search/?query=:query',
        import: ip + '/api/payment_method/import'
      },
      BusinessUnits: {
        list: ip + '/api/business_units/',
        active: ip + '/api/business_units/active',
        single: ip + '/api/business_units/:id',
        import: ip + '/api/business_units/import'
      },
      industry: {
        list: ip + '/api/industries',
        new: ip + '/api/industry',
        single: ip + '/api/industry/:id',
        import: ip + '/api/industry/import',
        companies: ip + '/api/industry/:code/companies'
      },
      paymentTerms: {
        list: ip + '/api/payment_term/list/all',
        masterList: ip + '/api/payment_term/',
        single: ip + '/api/payment_term/:id',
        search: ip + '/api/payment_term/search/?query=:query',
        import: ip + '/api/payment_term/import'
      },
      commodity: {
        list: ip + '/api/commodity?code=:code',
        listChild: ip + '/api/commodity/list/child?code=:code',
        masterList: ip + '/api/commodity',
        single: ip + '/api/commodity/:id',
        search: ip + '/api/commodity/search/?query=:query',
        searchAllLevel: ip + '/api/commodity/list/search?keywords=:keywords',
        import: ip + '/api/commodity/import'
      },
      exchangeRate: {
        single: ip + '/api/companies/:id/exchange_rate/:source_id/:target_id',
        masterList: ip + '/api/companies/:id/exchange_rate/',
        search: ip + '/api/companies/:id/exchange_rate/search/?query=:query',
        rate: ip + '/api/companies/:id/exchange_rate/:rate_id',
        import: ip + '/api/companies/:id/exchange_rate/import'
      },
      globalExchangeRate: {
        import: ip + '/api/companies/global/exchange_rate/import'
      },
      addresses: {
        list: ip + '/api/address',
        single: ip + '/api/address/:id',
        import: ip + '/api/address/import'
      },
      approvals: {
        single: ip + '/api/approval/:id',
        draftPreview: ip + '/api/approval-preview',
        dataPreview: ip + '/api/approval-data-preview',
        postDataPreview: ip + '/api/post-approval-data-preview',
        status: ip + '/api/approval/:approval_id/action/:status',
        addApprover: ip + '/api/approval/:id/ad-hoc-approver',
        removeAdhoc: ip + '/api/approval/:id/step/:step/position/:positionId',
        multiApprovalActions: ip + '/api/approval/mass-action/:action',
        v2: {
          status: ip + '/api/v2/approval/:approval_id/action/:status'
        }
      },
      available: {
        single: ip + '/api/available/:model/:field'
      },
      auditList: {
        get: ip + '/api/audits',
        raw: ip + '/api/audits/raw/:id'
      },
      accountCode: {
        singleCode: ip + '/api/account_code/:code/:company_code',
        list: ip + '/api/companies/:id/account_code/list/child?code=:code',
        prList: ip + '/api/account_code',
        single: ip + '/api/companies/:id/account_code/:accountCode_id',
        search: ip + '/api/companies/:id/account_code/search/?query=:query',
        add: ip + '/api/companies/:id/account_code/',
        import: ip + '/api/companies/:id/account_code/import/',
        export: ip + '/api/companies/:company_code/account-code/export',
      },
      accountCodeGroup: {
        search: ip + '/api/gl-account-code-group'
      },
      assetCategory: {
        list: ip + '/api/asset-category',
        single: ip + '/api/asset-category/:uuid',
        add: ip + '/api/asset-category/:uuid',
        get: ip + '/api/asset-category/company/:code',
        getByCode : ip + '/api/asset-category/:code/:companyCode/asset-category'
      },
      companyAsset: {
        list: ip + '/api/company-asset',
        single: ip + '/api/company-asset/:uuid',
        get: ip + '/api/company-asset/company/:code'
      },
      image: {
        pr: ip + '/api/items/images/',
        user: ip + '/api'
      },
      permissions: {
        userList: ip + '/api/users/:id/permissions',
        list: ip + '/api/permissions/',
        parameters: ip + '/api/permissions/class/:class'
      },
      roles: {
        userList: ip + '/api/users/:id/roles',
        list: ip + '/api/roles/:id',
        position: ip + '/api/users/:id/:positionId',
        managePermissions: ip + '/api/roles/:id/permissions/:pid',
        listPermissions: ip + '/api/roles/:id/permissions',
        users: ip + '/api/roles/:id/users',
        importUserRoles: ip + '/api/roles/import',
        companyRoles: ip + '/api/security/roles/company',
        rolePermissions: ip + '/api/security/roles/permission'
      },
      escalation: {
        add: ip + '/api/users/:id/manager/:mid',
        delete: ip + '/api/users/:id/manager/'
      },
      reports: {
        generate: ip + '/api/reports/:id',
        download: ip + '/api/reports/download/:id',
        checkStatus: ip + '/api/reports/:id/check',
        v2: {
          config: ip + '/api/sys_conf/minion_report',
          generate: ip + '/api/v2/reports/:id'
        }
      },
      security: {
        password: ip + '/api/sys_conf/mb_security',
        tenant: ip + '/api/sys_conf/tenant'
      },
      delegation: {
        delegateToAUser: ip + '/api/users/:userId/delegate/:delegateeId',
        massDelegate: ip + '/api/users/:userId/mass-delegate',
        delegatedToAUser: ip + '/api/users/:userId/delegatees',
        list: ip + '/api/delegations/:delegationId',
        single: ip + '/api/delegations/:id'
      },
      export: {
        generate: ip + '/api/exports',
        download: ip + '/api/exports/download/:export_hash',
        checkStatus: ip + '/api/exports/:id/check'
      },
      genericSearch: {
        searchBasePath: ip + '/api/search',
        search: ip + '/api/search/:module'
      },
      tenant: {
        image: ip + '/api/tenants/image',
        singleTenantById: ip + '/api/tenants/:id',
        supplierWebTnc: ip + '/api/tenants/supplier-web-tnc',
        getSupplierWebTnc: ip + '/api/supplier-web-tnc',
        policyDisclaimer: ip + '/api/tenant/policy-disclaimer'
      },
      purchaseRequisitions: {
        getSinglePR: ip + '/api/prsNew/:id',
        searchPRs: ip + '/api/prsNew/search',
        createPR: ip + '/api/prsNew/',
        createPRWithItems: ip + '/api/prsNew/from-catalog-cart',
        updatePRCompanyInfo: ip + '/api/prsNew/:id/company',
        checkPRCompanyInfo: ip + '/api/prsNew/:id/company-check',
        getAvailableDeliveryAddresses: ip + '/api/companies/:id/delivery-addresses',
        setPRTitle: ip + '/api/prsNew/:id/title',
        setRemarks: ip + '/api/prsNew/:id/remarks',
        setPRContactPerson: ip + '/api/prsNew/:id/contact-person',
        pdfHash: ip + '/api/prsNew/:id/pdf',
        getPRContactPersons: ip + '/api/companies/:id/getAllActiveUsers',
        getAvailableRequestorsList: ip + '/api/costcenters/:id/getAllActiveUsers',
        setExpenseType: ip + '/api/prsNew/:id/expense-type',
        setPaymentTerm: ip + '/api/prsNew/:id/payment-term',
        setRequestedBy: ip + '/api/prsNew/:id/requested-by',
        setNeededByDate: ip + '/api/prsNew/:id/needed-by-date',
        lumpSum: ip + '/api/prsNew/:id/lump-sum-discount',
        lumpSumAccountCode: ip + '/api/prsNew/:id/lump-sum-discount-account-code',
        submitPR: ip + '/api/prsNew/:id/submit',
        withdrawPR: ip + '/api/prsNew/:id/withdraw',
        collaborators: ip + '/api/prsNew/:id/collaborators',
        item: ip + '/api/pr-items/:id/:action',
        splitPO: ip + '/api/pr-items/:id/split-po',
        splitPOResponsive: ip + '/api/pr-items/:id/split-po-responsive',
        starring: ip + '/api/prsNew/:id/star/user/:user',
        supplierNotes: ip + '/api/prsNew/:id/supplier_note',
        updateSkipAutoSendPO: ip + '/api/prsNew/:id/skip-auto-send-po',
        duplicate: ip + '/api/prsNew/:id/duplicate',
        moveToDraft: ip + '/api/prsNew/:id/to_draft',
        nonCatalogImage: ip + '/api/pr-items/:id/noncatalog-details',
        updateNonCatalogImage: ip + '/api/pr-items/:id/non-catalog-image',
        requestEditableCopy: ip + '/api/prsNew/:id/requestEditableCopy',
        submitEditedCopy: ip + '/api/prsNew/:id/submitEditedCopy',
        cancelEditedCopy: ip + '/api/prsNew/:id/cancelEditedCopy',
        resetApproval: ip + '/api/approval/:approval_id/reset-step',
        updateIntegrationInfo: ip + '/api/prsNew/:id/integration-information',
        resendPendingPrEmail: ip + '/api/prsNew/:id/resend-pending-email',
        duplicateMultiItem: ip + '/api/prsNew/:id/duplicate-multi-line',
        config: ip + '/api/sys_conf/pr_config',
        research: ip + '/api/research/getAll/:company_code',
        specialEvent: ip + '/api/special-event/getAll/:company_code',
        studentLifeEvent: ip + '/api/student-life-event/getAll/:company_code'
      },
      purchaseRequisitionsV2: {
        getSinglePR: ip + '/api/v2/requisitions/:requisition_id',
        searchPRs: ip + '/api/v2/requisitions/search',
        createPR: ip + '/api/v2/requisitions/',
        createPRWithItems: ip + '/api/v2/requisitions/from-catalog-cart',
        updatePRCompanyInfo: ip + '/api/v2/requisitions/:requisition_id/company',
        checkPRCompanyInfo: ip + '/api/v2/requisitions/:requisition_id/company-check',
        getAvailableDeliveryAddresses: ip + '/api/companies/:id/delivery-addresses',
        setPRTitle: ip + '/api/v2/requisitions/:requisition_id/title',
        setRemarks: ip + '/api/v2/requisitions/:requisition_id/remarks',
        setPRContactPerson: ip + '/api/v2/requisitions/:requisition_id/contact-person',
        pdfHash: ip + '/api/v2/requisitions/:requisition_id/pdf',
        setExpenseType: ip + '/api/v2/requisitions/:requisition_id/expense-type',
        setPaymentTerm: ip + '/api/v2/requisitions/:requisition_id/payment-term',
        setRequestedBy: ip + '/api/v2/requisitions/:requisition_id/requested-by',
        setNeededByDate: ip + '/api/v2/requisitions/:requisition_id/needed-by-date',
        lumpSum: ip + '/api/v2/requisitions/:requisition_id/lump-sum-discount',
        lumpSumAccountCode: ip + '/api/v2/requisitions/:requisition_id/lump-sum-discount-account-code',
        submitPR: ip + '/api/v2/requisitions/:requisition_id/submit',
        withdrawPR: ip + '/api/v2/requisitions/:requisition_id/withdraw',
        collaborators: ip + '/api/v2/requisitions/:requisition_id/collaborators',
        addItem: ip + '/api/v2/requisitions/:requisition_id/add-catalog-item',
        item: ip + '/api/v2/requisition-items/:requisition_item_id/:action',
        splitPO: ip + '/api/v2/requisitions/:requisition_id/requisition-items/:requisition_item_id/split-po',
        splitPOResponsive: ip + '/api/v2/requisitions/:requisition_id/requisition-items/:requisition_item_id/split-po-responsive',
        starring: ip + '/api/v2/requisitions/:requisition_id/star/user/:user',
        supplierNotes: ip + '/api/v2/requisitions/:requisition_id/supplier_note',
        updateSkipAutoSendPO: ip + '/api/v2/requisitions/:requisition_id/skip-auto-send-po',
        duplicate: ip + '/api/v2/requisitions/:requisition_id/duplicate',
        moveToDraft: ip + '/api/v2/requisitions/:requisition_id/to_draft',
        updateNonCatalogImage: ip + '/api/v2/requisitions/:requisition_id/requisition-items/:requisition_item_id/non-catalog-image',
        requestEditableCopy: ip + '/api/v2/requisitions/:requisition_id/requestEditableCopy',
        submitEditedCopy: ip + '/api/v2/requisitions/:requisition_id/submitEditedCopy',
        cancelEditedCopy: ip + '/api/v2/requisitions/:requisition_id/cancelEditedCopy',
        resetApproval: ip + '/api/approval/:approval_id/reset-step',
        updateIntegrationInfo: ip + '/api/v2/requisitions/:requisition_id/integration-information',
        resendPendingPrEmail: ip + '/api/v2/requisitions/:requisition_id/resend-pending-email',
        duplicateMultiItem: ip + '/api/v2/requisitions/:requisition_id/duplicate-multi-line',
        comments: ip + '/api/v2/requisitions/:referenceId/comment/:commentId',
        nonCatalogImage: ip + '/api/v2/requisition-items/:requisition_item_id/image',
        attachment: {
          list: ip + '/api/v2/requisitions/:requisition_id/attachment',
          single: ip + '/api/v2/requisitions/:requisition_id/attachment/:attachmentId'
        },

        // v2 only feature
        otherCharges: ip + '/api/v2/requisition-items/:requisition_item_id/set-other-charges',
        removeOtherCharges:ip + '/api/v2/requisition-items/:requisition_item_id/remove-other-charges/:field',
        accountAssignments: ip + '/api/v2/requisition-items/:requisition_item_id/account-assignments',
        setBudget: ip + '/api/v2/requisitions/:requisition_id/requisition-items/:requisition_item_id/set-budget',
        massEdit: ip + '/api/v2/requisitions/:requisition_id/mass-edit',
        headerInfo: ip + '/api/v2/requisitions/:requisition_id/header-info/:field',
        createNonCatalog: ip + '/api/v2/requisitions/:requisition_id/non-catalog',
        massUpdatePrStatus: ip + '/api/v2/requisitions/mass-update-status',
        validateCapApproverLimit: ip + '/api/v2/requisitions/:requisition_id/validate-cap-approver-limit'
      },
      imports: {
        now: ip + '/api/imports/now/'
      },
      catalogGroups: {
        activeList: ip + '/api/catalog_group/list',
        list: ip + '/api/catalog_group/:groupId',
        assignment: ip + '/api/catalog_group/:groupId/company/:companyId',
        import: ip + '/api/catalog_group/import',
        activeUsers: ip + '/api/catalog_group/:groupCode/active-users'
      },
      catalogCode: {
        single: ip + '/api/catalog_code/:id/:action',
        list: ip + '/api/catalog_code/list'
      },
      approvalEngine: {
        position: {
          get: ip + '/api/positions',
          single: ip + '/api/positions/:id',
          export: ip + '/api/positions/export'
        },
        positionAssign: {
          get: ip + '/api/position-assignments',
          single: ip + '/api/position-assignments/:id',
          export: ip + '/api/position-assignments/export'
        },
        approvalTemplate: {
          get: ip + '/api/approval-templates/',
          detail: ip + '/api/approval-templates/:id',
          info: ip + '/api/approval-templates-info',
          export: ip + '/api/approval-templates/export',
          hash: ip + '/api/approval-templates/zip/:page',
          checkStatus: ip + '/api/approvalTemplatezip/:id/check'
        },
        approvalTemplateHistory: {
          get: ip + '/api/approval-history',
          single: ip + '/api/approval-history/:id',
          all: ip + '/api/approval-history-template'
        }
      },
      adtaf: {
        getAdtaf: ip + '/api/adtaf/:id',
        processAdtaf: ip + '/api/adtaf/:requisitionItemId'
      },
      cerf: {
        getCerf: ip + '/api/cerf/:id',
        processCerf: ip + '/api/cerf/:requisitionItemId'
      },
      budget: {
        list: ip + '/api/budgets',
        single: ip + '/api/budgets/:id/:action',
        apportionAllocation: ip + '/api/budget-apportion-allocations',
        export: ip + '/api/budgets/:id/export/:type/assignment/:assignmentId',
        nonPurchasingCost: ip + '/api/budget/non-purchasing-cost/submit',
        getItems: ip + '/api/budget/:id/new-get-items?item_type=:type',
        glAccounts: ip + '/api/budget/gl-accounts/submit',
        projectCodes: ip + '/api/budget/project-codes/submit',
        nonPurchasingCostExport: ip + '/api/budget/non-purchasing-cost/export',
        glAccountsExport: ip + '/api/budget/gl-accounts/export',
        projectCodesExport: ip + '/api/budget/project-codes/export',
        glAccountsList: ip + '/api/budget/gl-accounts',
        revisionSummary: ip + '/api/budget/:id/revision-summary',
        massUpdateBudgetOwner: ip + '/api/budget/mass-update-budget-owner',
        resendPendingBudgetEmail: ip + '/api/budget/:id/resend-pending-email',
      },
      pdfPreview: {
        preview: ip + '/api/preview-pdf/:id'
      },
      globalPDFHashDownload: ip + '/api:pdf_hash',
      globalZipHashDownload: ip + '/api/approvalTemplatezip/:zip_hash',
      devInfo: ip + '/api/dev-info',
      bifrost: {
        getMappingInfo: ip + '/api/bifrost/search-entity',
        getMappingList: ip + '/api/bifrost/supplier-mapping-list',
        companyEnvironment: ip + '/api/bifrost/company-environment',
        supplierEnvironment: ip + '/api/bifrost/supplier-environment',
        taxEnvironment: ip + '/api/bifrost/tax-environment',
        paymentTermEnvironment: ip + '/api/bifrost/payment-term-environment',
        uomEnvironment: ip + '/api/bifrost/uom-environment',
        purchaseOrder: ip + '/api/bifrost/bifrost-po',
        supplier: ip + '/api/bifrost/search-supplier',
        export: ip + '/api/bifrost/export',
        mappedSupplier: ip + '/api/bifrost/mapped-supplier',
        inbound: ip + '/api/bifrost/upload-inbound',
        environment: ip + '/api/bifrost/environment',
        schedule: ip + '/api/bifrost/schedule',
        storage: ip + '/api/bifrost/storage',
        uploadRule: ip + '/api/bifrost/upload-rule',
        storageDownload: ip + '/api/bifrost/storage-download',
        storageDownloadFile: ip + '/api/bifrost/storage/download/',
        mappingModule: ip + '/api/bifrost/mapping-module',
        exportMappingModule: ip + '/api/bifrost/mapping-module-export',
        reinboundDelivery: ip + '/api/bifrost/jde-data-inbound',
        bifrostMapping: ip + '/api/bifrost/suppliers-mapping/:id',
        storagePath: ip + '/api/bifrost/storage-path',
        inboundInfo: ip + '/api/bifrost/data-inbound-info',
        downloadInboundFile: ip + '/api/bifrost/download-data-inbound',
        outboundInfo: ip + '/api/bifrost/data-outbound-info',
        downloadOutboundFile: ip + '/api/bifrost/download-data-outbound',
        fixedAssetSap: ip + '/api/bifrost/create-sap-fixed-asset',
        fixedAssetMsgp: ip + '/api/bifrost/create-msgp-fixed-asset',
        bifrostProcessStatus: ip + '/api/bifrost/process-status'
      },
      emailLog: {
        bounced: ip + '/api/bounced-email-log/'
      },
      supplierWeb: {
        adminStatistics: ip + '/api/supplier-web/admin-statistics'
      },
      admin: {
        sendAnnouncement: ip + '/api/admin/announcement'
      },
      roleAssignment: {
        organizationRoles: ip + '/api/role-assignments/:org_id/roles',
        supplierWebRoles: ip + '/api/role-assignments/:org_id/supplier-web-roles'
      },
      analytics: {
        metrix: ip + '/api/users/analytics-param',
        dashboard: ip + '/api/analytics/dashboard',
        exclusionPo: ip + '/api/analytics/exclusion-po',
        trendOfSpendingReport: ip + '/api/analytics/trend-of-spending-report',
        trendOfSpendingReportDrillDown: ip + '/api/analytics/trend-of-spending-report-drilldown',
        totalSpendingByItemType: ip + '/api/analytics/total-spending-by-item-type',
        totalSpendingByItemTypeDrillDown: ip + '/api/analytics/total-spending-by-item-type-drilldown',
        topSpendingByCommodityCode: ip + '/api/analytics/top-spending-by-commodity-code',
        topSpendingByCommodityCodeLevel: ip + '/api/analytics/top-spending-by-commodity-code-level',
        topPurchaseItem: ip + '/api/analytics/top-purchase-item',
        topPurchaseItemDrillDown: ip + '/api/analytics/top-purchase-item-drilldown',
        topSpendingBySupplier: ip + '/api/analytics/top-spending-by-supplier',
        topSpendingBySupplierDrillDown: ip + '/api/analytics/top-spending-by-supplier-drilldown',
        totalSpendingByExpenseType: ip + '/api/analytics/top-spending-by-expense-type',
        totalSpendingByExpenseTypeDrillDown: ip + '/api/analytics/top-spending-by-expense-type-drilldown'
      },
      minion: {
        checkConnection: ip + '/api/minion/check-connection',
        getExportFile: ip + '/api/minion/retrieve-export-file/:export_log_id'
      },
      laravelLog: ip + '/api/logs',
      advancedSearch: ip + '/api/find/:module',
      maintenance: {
        changeGrnDoNumber: ip + '/api/maintenance/change-grn-do-number',
        changeMappingFieldSize: ip + '/api/maintenance/change-mapping-field',
        reoutboundTool: ip + '/api/maintenance/reoutbound',
        showSuppliers: ip + '/api/maintenance/show-suppliers',
        companyContractJob: ip + '/api/maintenance/company-contract-job',
        integrationConfig: ip + '/api/maintenance/integration-configuration',
        usersUuid: ip + '/api/maintenance/users-uuid',
        changeAllUsersPassword: ip + '/api/maintenance/change-all-users-password',
        restartDataSync: ip + '/api/maintenance/restartDataSync'

      },
      holiday: {
        singleHoliday: ip + '/api/holiday/:id'
      },
      featureToggle: {
        single: ip + '/api/feature-toggle/:key',
        multiple: ip + '/api/feature-toggle/multiple'
      },
      appConfig: {
        offlineConfig: ip + '/api/app-offline-config'
      },
      privateAccess: {
        token: ip + '/api/private-access-token',
        activeUsers: ip + '/api/private-access-token/get-all-active-user'
      },
      poConsolidation: {
        consolidate: ip + '/api/po-consolidation/consolidate',
        checkToleranceLimit: ip + '/api/po-consolidation/check-tolerance-limit',
        poApprovalPreview: ip + '/api/po-consolidation/po-approval-preview',
        updateConsolidationStatus: ip + '/api/po-consolidation/:id/update-status',
        massUpdateConsolidationStatus: ip + '/api/po-consolidation/mass-update-status'
      },
      quotation: {
        single: ip + '/api/quotation/:id',
        create: ip + '/api/quotation',
        update: ip + '/api/quotation/:quotation_id',
        default: ip + '/api/quotation/generate-default',
        attachments: ip + '/api/quotation/:quotation_id/attachment',
        singleAttachment: ip + '/api/quotation/:quotation_id/attachment/:attachment_id',
      },
      activities: {
        single: ip + '/api/activities/:uuid'
      },
      qaTools: {
        resetUsersApprovalTracks: ip + '/api/reset_users_tracking'
      },
      kharon: {
        pushToKharon: ip + '/api/kharon/push-to-kharon',
        commandLogs: ip + '/api/kharon/command-logs',
        schedulerConfigs: ip + '/api/kharon/scheduler-configs',
        updateSchedulerConfig: ip + '/api/kharon/scheduler-config/:id',
        users: ip + '/api/kharon/users',
        user: ip + '/api/kharon/user/:id'
      },
      plutus: {
        login: ip + '/api/plutus/login'
      },
      priceComparison: {
        single: ip + '/api/price-comparison/:id',
        submit: ip + '/api/price-comparison/:id/submit',
        calculateComparison: ip + '/api/price-comparison/calculate-comparison',
        singleAttachment: ip + '/api/price-comparison/:id/attachment/:itemIndex/:editable',
        getAttachmentList: ip + '/api/price-comparison/:id/attachments',
        getAttachment: ip + '/api/price-comparison/:id/attachment/:attachmentId',
        updateAttachment: ip + '/api/price-comparison/:id/attachment/:attachmentId',
        deleteAttachment: ip + '/api/price-comparison/:id/attachment/:attachmentId/:itemIndex/:editable',
        comments: ip + '/api/price-comparison/:referenceId/comment/:commentId',
        generatePdf: ip + '/api/price-comparison/generate-pdf/:id',
        submitEditedCopy: ip + '/api/price-comparison/:requisition_id/submitEditPrItem',
      },
      tender: {
        group: {
          list: ip + '/api/search/tender-group',
          create: ip + '/api/tender/group',
          update: ip + '/api/tender/group/:id',
          detail: ip + '/api/tender/group/:id',
        },
        initial: ip + '/api/tender/initial',
        detail: ip + '/api/tender/:id',
        create: ip + '/api/tender/create',
        save: ip + '/api/tender/save/:id',
        findApprover: ip + '/api/tender/find-approver-list/:id',
        comments: ip + '/api/tender/:referenceId/comment/:commentId',
        findSupplier: ip + '/api/tender/find-supplier-list/:id',
        uploadMemoAttachment: ip + '/api/tender/upload-memo-attachment',
        getMemoAttachment: ip + '/api/tender/get-memo-attachment/:tenderId/:memoId',
        sendEmailMemo: ip + '/api/tender/send-email-memo-attachment/:tenderId',
        manageMemoAttachment: ip + '/api/tender/manage-memo-attachment/:tenderId',
        attachment: {
          list: ip + '/api/tender/:id/attachment',
          single: ip + '/api/tender/:id/attachment/:attachmentId'
        },
        submit: ip + '/api/tender/submit/:id',
        delete: ip + '/api/tender/delete/:id',
        moveToDraft: ip + '/api/tender/move-to-draft/:id',
        deactivate: ip + '/api/tender/deactivate/:id',
        reactivate: ip + '/api/tender/reactivate/:id',
        reactivateExpired: ip + '/api/tender/reactivate-expired/:id',
        createVO: ip + '/api/tender/create-vo/:id',
        cancelVO: ip + '/api/tender/cancel-vo/:id',
        voHistories: ip + '/api/tender/vo-histories/:id',
        revisions: ip + '/api/tender/revisions/:id',
        starringItem: ip + '/api/tender/star/:id/:user',
        generatePdf: ip + '/api/tender/generate-pdf/:id',
        findByCompany : ip + '/api/tender/get-tender-by-company/:companyCode',
        findByCatalog : ip + '/api/tender/get-tender-by-catalog',
        consumption : ip + '/api/tender/get-tender-consumption/:id',
        resendPendingTenderEmail: ip + '/api/tender/resend-pending-email/:id'
      },
      faq: {
        v2: {
          documents: {
            upload: ip + '/api/v2/faq/upload-faq-documents',
            get: ip + '/api/v2/faq/get-faq-documents/:id',
            delete: ip + '/api/v2/faq/delete-faq-documents/:id',
          },
          comments: ip + '/api/v2/faq/comments',
        }
      },
      azureAd: {
        login: ip + '/api/users/adLogin',
        callback: ip + '/api/users/azure-redirect',
        checkSocialLogin: ip + '/api/users/social-login',
      },
      twoFA: {
        verifyKey: ip + '/api/2fa/verify-key',
        verifyNewKey: ip + '/api/2fa/verify-new-key',
        verifycheck: ip + '/api/2fa/check',
      },

      // TODO: Pending update to use data from company items #14074, #14073
      // stock: {
      //   balance: ip + '/api/stock-balance/:id'
      // },

      // TODO: Pending update to use purchase history module #13204, #14047
      // purchasePrice: ip + '/api/purchase-price/:id/:company'
    }
  }
})());
